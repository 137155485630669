const getCurrency = (locale = '') => {
	if (locale.endsWith('CA')) {
		return 'CAD';
	} else if (locale.endsWith('MX')) {
		return 'MXN';
	}
	// Default to USD when locale is not CA or MX
	return 'USD';
};
const getPrice = (locale, currency, price) => {
	return new Intl.NumberFormat(locale.replace('_', '-'), {
		style: 'currency',
		currency,
		maximumFractionDigits: 0
	}).format(price);
};
export const getFormattedPrice = (
	locale = '',
	includeDestinationChargeInPrice,
	vehicle
) => {
	const currency = getCurrency(locale);
	const { minStartingAtPrice, priceWithCharges } = vehicle;
	if (includeDestinationChargeInPrice && priceWithCharges) {
		return getPrice(locale, currency, priceWithCharges);
	} else if (minStartingAtPrice) {
		return getPrice(locale, currency, minStartingAtPrice);
	}
	return '';
};
