import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

import VehicleCard from './VehicleCard';

const VehicleCards = ({
	handleLinkClick,
	vehicles,
	inputWidth,
	suggestion,
	locale
}) => {
	const labels = useLabels();

	const allVehiclesPath =
		suggestion && suggestion?.count > 1 ? suggestion?.path : '';

	const viewLinkLabel = labels.get('VIEW_ALL_VEHICLES_SITE_SEARCH');
	const handleClick = () => {
		handleLinkClick('allInventoryLink', viewLinkLabel, '', '');
	};

	const singleColumnWidth = 600;
	const isDoubleColumn = inputWidth > singleColumnWidth;
	if (vehicles?.length > 0) {
		let keyCount = 0;
		return (
			<div
				data-testid="modern-vehicle-cards"
				role="region"
				aria-labelledby="vehicle-cards-heading"
				className={`${isDoubleColumn ? '' : 'pb-5'}`}
			>
				{allVehiclesPath && (
					<a
						onClick={handleClick}
						href={allVehiclesPath}
						className="d-flex align-items-center font-weight-bold py-3 px-4"
					>
						<span>
							{viewLinkLabel}
							<i
								className="ddc-icon ddc-icon-arrow-details ml-3"
								aria-hidden="true"
							/>
						</span>
					</a>
				)}
				<h3 id="vehicle-cards-heading" className="sr-only">
					{labels.get('VEHICLE_SEARCH_RESULTS')}
				</h3>
				{vehicles.slice(0, 5).map((vehicle) => {
					const {
						path,
						images: [image1],
						title,
						price,
						odometer,
						odometerUnit,
						condition
					} = vehicle;

					const titleString = Array.isArray(title)
						? title.join(' ')
						: title;

					const image = image1;
					const capitalizedCondition = condition.toUpperCase();
					keyCount += 1;
					return (
						<VehicleCard
							href={path || null}
							imageUrl={image.url}
							key={keyCount}
							title={titleString}
							price={price}
							odometer={odometer}
							odometerUnit={odometerUnit}
							condition={capitalizedCondition}
							handleLinkClick={handleLinkClick}
							locale={locale}
						/>
					);
				})}
			</div>
		);
	}
	return null;
};

VehicleCards.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			title: PropTypes.arrayOf(PropTypes.string),
			images: PropTypes.arrayOf(PropTypes.shape({})),
			price: PropTypes.string,
			odometer: PropTypes.string,
			odometerUnit: PropTypes.string,
			condition: PropTypes.string
		})
	),
	handleLinkClick: PropTypes.func,
	inputWidth: PropTypes.number.isRequired,
	suggestion: PropTypes.shape({
		count: PropTypes.number,
		facets: PropTypes.string,
		path: PropTypes.string,
		title: PropTypes.string
	}),
	locale: PropTypes.string.isRequired
};

export default VehicleCards;
