import React from 'react';
import { useRequestData, usePrefs } from 'wsm-common-data';
import TabText from '../components/TabText';
import TabPane from '../components/TabPane';
import TabMenu from '../components/TabMenu';
import Disclaimer from '../components/Disclaimer';

const Widget = () => {
	const { deviceType } = useRequestData();
	const { tabMenuPosition, tabMenuOverlay } = usePrefs();
	const isMobile = deviceType === 'MOBILE';

	const renderDesktop = () => {
		if (tabMenuPosition === 'top' && tabMenuOverlay === 'outside') {
			return (
				<>
					<div>
						<TabMenu />
					</div>
					<TabPane />
				</>
			);
		} else {
			return (
				<>
					<TabPane />
					<TabMenu />
				</>
			);
		}
	};

	// Renders Disclaimer, TabText and TabMenu outside of TabPane
	const renderMobile = () => {
		return (
			<>
				<TabMenu />
				<TabPane />
				<Disclaimer />
				<TabText />
			</>
		);
	};

	const contentToRender = isMobile ? renderMobile() : renderDesktop();

	return <>{contentToRender}</>;
};

export default Widget;
