import React from 'react';
import { useSelector } from 'react-redux';
import { useRequestData, usePrefs } from 'wsm-common-data';
import TabMenuItem from './TabMenuItem';

const TabMenu = () => {
	const { windowId, deviceType } = useRequestData();
	const items = useSelector((state) => state.items.items);
	const { tabMenuOverlay, tabMenuPosition } = usePrefs();

	const isMobile = deviceType === 'MOBILE';

	let wrapperStyles = {};
	if (tabMenuOverlay === 'inside' && !isMobile) {
		wrapperStyles = {
			position: 'absolute',
			[tabMenuPosition]: '0',
			right: '0',
			width: '100%'
		};
	}

	return (
		<div style={wrapperStyles}>
			<div className="row d-flex justify-content-center align-items-center my-5">
				<div
					className="col-md-6 d-flex flex-row flex-wrap justify-content-center align-items-center mx-2"
					role="tablist"
				>
					{items.map((item, i) => {
						return (
							<TabMenuItem
								tabMenuItemIndex={i}
								key={`${windowId + i}-tab-menu-item-${
									item.heading
								}`}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TabMenu;
