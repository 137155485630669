import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { usePrefs, useLabels } from 'wsm-common-data';
import { setCurrentItem } from '../actions/setItems';
import { selectItemAtIndex } from '../reducers/items-selectors';
import './TabMenuItem.scss';

const handleTracking = ({
	element,
	index,
	text: { heading },
	widgetIntention
}) => {
	let event = 'ddc.click.tab';
	const eventData = {
		element,
		index
	};

	if (widgetIntention === 'color picker') {
		event = 'ddc.click.colorpicker';
		eventData.color = heading;
	} else {
		eventData.text = heading;
	}

	window.DDC.pushEvent({ event, eventData });
};

const Image = ({ tabMenuData, isActive = false }) => (
	<span
		className={`tab-menu-item-image ${isActive ? 'active' : ''} ${
			tabMenuData.tabMenuImageShape
		}  ${tabMenuData.borderColor}`}
		style={{
			background: `url(${tabMenuData.tabMenuItemData.tabImageSrc})`
		}}
	/>
);

const Text = ({ tabMenuData, labels, isActive }) => (
	<p
		className={`tab-menu-item-label text-${tabMenuData.tabMenuTextColor} ${
			tabMenuData.tabMenuButtonStyle === 'text-image'
				? 'position-absolute'
				: ''
		} ${isActive ? `active border-${tabMenuData.tabMenuTextColor}` : ''}`}
	>
		{labels.get(tabMenuData.tabMenuItemData.buttonText)}
	</p>
);

const getTabMenuButton = (tabMenuData, labels, isActive) => {
	let component = null;
	switch (tabMenuData.tabMenuButtonStyle) {
		case 'text':
			component = (
				<Text
					tabMenuData={tabMenuData}
					labels={labels}
					isActive={isActive}
				/>
			);
			break;
		case 'image':
			component = <Image tabMenuData={tabMenuData} isActive={isActive} />;
			break;
		case 'text-image':
			component = (
				<>
					{isActive && (
						<Text tabMenuData={tabMenuData} labels={labels} />
					)}
					<Image tabMenuData={tabMenuData} />
				</>
			);
			break;
		default:
			component = (
				<Text
					tabMenuData={tabMenuData}
					labels={labels}
					isActive={isActive}
				/>
			);
	}

	return component;
};

const TabMenuItem = ({ tabMenuItemIndex }) => {
	const dispatch = useDispatch();
	const {
		tabMenuButtonStyle,
		tabMenuImageShape,
		tabMenuImageBorderColor,
		tabMenuTextColor,
		widgetIntention
	} = usePrefs();

	const labels = useLabels();

	const tabMenuItemData = useSelector(selectItemAtIndex(tabMenuItemIndex));
	const isActive =
		tabMenuItemIndex === useSelector((state) => state.items.currentItem);

	const ariaLabel =
		labels.get(tabMenuItemData.buttonText) ??
		`Tab Button ${tabMenuItemIndex + 1}`;

	const borderColor =
		tabMenuImageBorderColor === 'none'
			? ''
			: `border-${tabMenuImageBorderColor}`;
	const handleClick = (e) => {
		dispatch(setCurrentItem(tabMenuItemIndex));
		handleTracking({
			element: e.target,
			index: tabMenuItemIndex,
			text: {
				heading: labels.get(tabMenuItemData.heading)
			},
			widgetIntention
		});
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			dispatch(setCurrentItem(tabMenuItemIndex));
			handleTracking({
				element: e.target,
				index: tabMenuItemIndex,
				text: {
					heading: labels.get(tabMenuItemData.heading)
				},
				widgetIntention
			});
		}
	};

	const tabMenuData = {
		tabMenuButtonStyle,
		tabMenuImageShape,
		borderColor,
		tabMenuTextColor,
		tabMenuItemData,
		tabMenuItemIndex,
		isActive
	};

	return (
		<div
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			role="tab"
			tabIndex={0}
			className="d-flex flex-column align-items-center jusity-content-between px-3 py-2 position-relative"
			aria-label={ariaLabel}
			aria-selected={isActive}
		>
			{getTabMenuButton(tabMenuData, labels, isActive)}
		</div>
	);
};

const propTypesObj = {
	tabMenuButtonStyle: PropTypes.string,
	tabMenuImageShape: PropTypes.string,
	borderColor: PropTypes.string,
	tabMenuTextColor: PropTypes.string,
	tabMenuItemData: PropTypes.shape({
		tabImageSrc: PropTypes.string,
		buttonText: PropTypes.string
	}),
	tabMenuItemIndex: PropTypes.number,
	isActive: PropTypes.bool
};

Image.propTypes = {
	tabMenuData: PropTypes.shape(propTypesObj),
	isActive: PropTypes.bool
};

Text.propTypes = {
	tabMenuData: PropTypes.shape(propTypesObj),
	labels: PropTypes.func,
	isActive: PropTypes.bool
};

TabMenuItem.propTypes = {
	tabMenuItemIndex: PropTypes.number.isRequired
};

export default TabMenuItem;
