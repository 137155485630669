import { transformAddData } from 'wsm-contact-transformer';
import { checkTopLevelAccount } from '../utilities/account';
import { a, CONTACT_OPTIONS } from '../global-constants';

const initialState = {
	name: '',
	id: '',
	ancestorAccountIds: [],
	address: {},
	departments: []
};

const account = (state = initialState, { type, payload }) => {
	switch (type) {
		case a.SET_ACCOUNT: {
			let accountData = {};

			if(Object.keys(payload).length) {
				const { name, id, ancestorAccountIds } = payload;
				// Transform dealerInfo and phoneNumbers
				const { dealerInfo, phoneNumbers } = transformAddData(payload, CONTACT_OPTIONS);
				accountData = {
					name,
					id,
					topLevelAccount: checkTopLevelAccount(ancestorAccountIds),
					dealerInfo,
					phoneNumbers,
				};
			}

			return {
				...state,
				...accountData
			};
		}
		default:
			return state;
	}
};

export default account;
