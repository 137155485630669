import { a } from '../global-constants';

const initialState = {
  id: ''
};

const accountId = (state = initialState, { type, payload }) => {
	switch (type) {
		case a.SET_ACCOUNT_ID: {
			return {
				...state,
				id: payload
			};
		}
		default:
			return state;
	}
};

export default accountId;
