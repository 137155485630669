import { encodeQueryValue } from './encodeQueryValue';

export const getModelLink = (modelLinkPath, modelLinkParameters, vehicle) => {
	const validParams = ['year', 'make', 'model', 'gvBodyStyle'];
	const modelLinkParameterArray = modelLinkParameters
		.split(',')
		.reduce((paramsArray, currentParam) => {
			if (validParams.includes(currentParam)) {
				// Transforming to string in case of number
				const value = vehicle[currentParam]?.toString();
				if (!value) {
					return paramsArray;
				}
				const encodedValue = encodeQueryValue(value);
				paramsArray.push(
					`${currentParam}=${encodedValue?.replace(/ /g, '+')}`
				);
			}
			return paramsArray;
		}, []);
	return `${modelLinkPath}?${modelLinkParameterArray.join('&')}`;
};
