import React, { useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestData, usePrefs, useFlags, useLabels } from 'wsm-common-data';
import { Error } from './Error';
import { Loading } from './Loading';
import { TabbedBodyStyles } from './TabbedBodyStyles';
import { requestModelSelectorData } from '../data/modelSelectorServiceFetcher';
import {
	loadingSelector,
	widgetDataSelector,
	vehiclesSelector
} from '../server/selectors';

export const DataFetcher = () => {
	const {
		displayStyle,
		showInventoryOnLotCount,
		excludeModelsWithoutInventory,
		showcaseMappingId,
		enableGroupInventoryConstraints
	} = usePrefs();

	const flags = useFlags();
	const labels = useLabels();
	const dispatch = useDispatch();
	const loading = useSelector(loadingSelector);
	const vehicles = useSelector(vehiclesSelector);
	const requestData = useRequestData();
	const { deviceType, locale, widgetName, windowId } = requestData;
	const { siteId, accountId, pageAlias, make, pageId, host } =
		useSelector(widgetDataSelector);

	const enableModelSelectorService = flags['enable-model-selector-service'];

	const imagePerspective =
		deviceType === 'MOBILE' || displayStyle === 'grid'
			? 'side-left'
			: 'front-left';

	useEffect(() => {
		requestModelSelectorData(dispatch, {
			uri: isBrowser ? window.location.pathname : '',
			make,
			locale,
			host,
			device: deviceType,
			siteId,
			accountId,
			pageId,
			windowId,
			referrer: isBrowser ? window.location.origin : '',
			pageAlias,
			widgetName,
			imagePerspective,
			showInventoryOnLotCount,
			enableModelSelectorService,
			excludeModelsWithoutInventory,
			showcaseMappingId,
			enableGroupInventoryConstraints
		});
	}, [
		dispatch,
		deviceType,
		make,
		host,
		siteId,
		accountId,
		locale,
		pageId,
		windowId,
		pageAlias,
		widgetName,
		imagePerspective,
		showInventoryOnLotCount,
		enableModelSelectorService,
		excludeModelsWithoutInventory,
		showcaseMappingId,
		enableGroupInventoryConstraints
	]);

	if (loading.status && !loading.error) {
		return <Loading />;
	} else if (
		(!loading.status && loading.error) ||
		Object.keys(vehicles).length === 0
	) {
		return <Error message={labels.get('MODEL_SELECTOR_UNAVAILABLE')} />;
	} else {
		return <TabbedBodyStyles data={vehicles} />;
	}
};
