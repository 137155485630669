const transformInventoryResults = (results, inventorySrpPath) => {
	let transformedResults = [];
	if (results.length > 1 || (results.length === 1 && results[0].count)) {
		transformedResults = results.map(({ suggestion, count, facetInfo }) => {
			return {
				title: suggestion,
				count,
				facets: Object.keys(facetInfo).join(','),
				path: facetInfo
					? `${inventorySrpPath}?${Object.keys(facetInfo)
							.map(
								(key) =>
									`${encodeURIComponent(
										key
									)}=${encodeURIComponent(facetInfo[key])}`
							)
							.join('&')}`
					: inventorySrpPath
			};
		});
	}
	return transformedResults;
};
export default transformInventoryResults;
