import { SET_CURRENT_ITEM, SET_ITEMS } from './types';

export function setItems(content) {
	return {
		type: SET_ITEMS,
		payload: content
	};
}

export const setCurrentItem = (index) => {
	return {
		type: SET_CURRENT_ITEM,
		payload: index
	};
};
