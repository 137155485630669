import { SET_CURRENT_ITEM, SET_ITEMS } from '../actions/types';

/* We can make the item state more complex */

const initialState = {
	items: [],
	currentItem: 0
};

export default function items(state = initialState, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_ITEMS:
				return { ...state, items: action.payload };
			case SET_CURRENT_ITEM:
				if (
					action.payload < 0 ||
					action.payload > state.items.length - 1
				)
					return { ...state, currentItem: 0 };
				else return { ...state, currentItem: action.payload };
			default:
				return state;
		}
	}
	return state;
}
