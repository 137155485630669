import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRequestData, useLabels } from 'wsm-common-data';
import { getHtmlWithDLData } from 'wsm-label-helper';
import { selectCurrentItem } from '../reducers/items-selectors';
import './Disclaimer.scss';

const Disclaimer = ({ textColor }) => {
	const activeItem = useSelector(selectCurrentItem);
	const { deviceType } = useRequestData();
	const labels = useLabels();
	const isMobile = deviceType === 'MOBILE';

	const desktopOnlyClasses = `disclaimer px-4 pb-4 text-${activeItem?.alignDisclaimer}`;

	if (!labels.get(activeItem?.disclaimer)) {
		return null;
	} else {
		return (
			<div
				className={`
					${!isMobile ? `${desktopOnlyClasses}` : ''}
					ddc-font-size-xsmall  
					text-${textColor} 
				`}
				style={!isMobile ? { [activeItem?.alignDisclaimer]: 0 } : {}}
			>
				<p className="mb-0">
					{getHtmlWithDLData(labels.get(activeItem?.disclaimer))}
				</p>
			</div>
		);
	}
};

Disclaimer.propTypes = {
	textColor: PropTypes.string
};

export default Disclaimer;
