import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';

const SearchLinks = ({
	type,
	showResults,
	data,
	iconClass,
	ariaLabel,
	handleLinkClick,
	isEmbedded
}) => {
	const labels = useLabels();

	const classNames = ['ddc-font-size-base', 'font-weight-bold', 'mt-0'];

	if (type === 'no-results') {
		classNames.push('sr-only');
	}

	if (type === 'inventory') {
		const isEverySuggestionCountOne = data.every(
			(item) => item.count === 1
		);
		if (isEverySuggestionCountOne) {
			return null;
		}
	}

	if (showResults === 'true' && data?.length > 0) {
		let keyCount = 0;
		const maxLinks = isEmbedded && type !== 'inventory' ? 25 : 5;
		const headingLabel =
			type === 'inventory'
				? `${labels.get('VIEW_SIMILAR_VEHICLES')}`
				: `${labels.get('WEBSITE_RESOURCES')}`;
		return (
			<div
				data-testid={`modern-${type}-search-links`}
				role="region"
				aria-labelledby={`${type}-search-links-heading`}
			>
				<span
					id={`${type}-search-links-heading`}
					className={setClassNames(classNames)}
				>
					{headingLabel}
				</span>
				<ul className="list-group mb-3 mt-3">
					{data.slice(0, maxLinks).map((item) => {
						if (
							(!item.title || !item.path) &&
							type === 'no-results'
						) {
							return null;
						}
						keyCount += 1;
						const handleClick = () => {
							let elementCTA = '';
							let linkType = '';
							let addRecentSearches = true;
							if (type === 'inventory') {
								linkType = 'inventoryLink';
								elementCTA = item.facets || '';
							} else if (type === 'page') {
								linkType = 'pageLink';
								elementCTA = item.title;
							} else if (type === 'no-results') {
								linkType = 'noResultsLink';
								elementCTA = item.title;
								addRecentSearches = false;
							}
							handleLinkClick(
								linkType,
								elementCTA,
								item.title,
								'',
								addRecentSearches
							);
						};
						return (
							<li key={keyCount} className="line-height-reset">
								<a
									className="d-flex align-items-center list-group-item"
									href={item.path}
									// eslint-disable-next-line max-len
									aria-label={`${labels.get('VISIT')} ${
										item.title
									} ${ariaLabel}`}
									onClick={handleClick}
								>
									<i
										className={`ddc-icon ${
											iconClass || item?.icon
										} ddc-icon-size-large mr-3`}
										aria-hidden="true"
									/>
									<span className="ddc-font-size-base">
										{item.title}
									</span>
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
	return null;
};

SearchLinks.propTypes = {
	type: PropTypes.oneOf(['inventory', 'page', 'no-results']).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	showResults: PropTypes.string,
	iconClass: PropTypes.string,
	ariaLabel: PropTypes.string,
	handleLinkClick: PropTypes.func,
	isEmbedded: PropTypes.bool
};

export default SearchLinks;
