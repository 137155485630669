// If we don't pass a valid HTML string to parse, it will not be happy about it.
import { setNewRelicCustomAttribute } from 'ddc-new-relic';

export const containsHtmlTags = (str) => {
	const regex = /<[^>]*>|[#;&]/g;
	if (regex.test(str)) {
		setNewRelicCustomAttribute('wsNavContainsHtml', str);
		return true;
	} else {
		return false;
	}
};
