import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import { getHtmlWithDLData } from 'wsm-label-helper';
import { selectCurrentItem } from '../reducers/items-selectors';
import './TabPane.scss';
import Disclaimer from './Disclaimer';

const TabPane = () => {
	const labels = useLabels();
	/* 
		aspect ratio for desktop device = 1:91:1 (566 px),
		mobile device = 16:9 (674 px)
	*/
	const { deviceType } = useRequestData();
	const imageRef = useRef(null);
	const [images, setImages] = useState([]);
	const [imgsLoaded, setImgsLoaded] = useState(false);
	const tabItems = useSelector((state) => state.items);
	const isMobile = deviceType === 'MOBILE';
	const imPolicyParam = isMobile ? '&h=674' : '&h=556';
	const { contentTextColor, aspectRatio } = usePrefs();
	const activeItem = useSelector(selectCurrentItem);
	const [verticalPos, horizontalPos] = activeItem.contentLocation?.split(
		'-'
	) ?? ['top', 'left'];

	const firstImageTab = tabItems.items.map((item) => item.imageSrc)[0];

	const mobileHeight = imPolicyParam.substring(
		imPolicyParam.indexOf('=') + 1
	);

	useEffect(() => {
		const observerRefValue = imageRef.current;

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const img = entry.target;
					img.src = img.dataset.src;
					observer.unobserve(img);

					setImages(tabItems.items.map((item) => item.imageSrc));

					const loadImage = (image) => {
						return new Promise((resolve, reject) => {
							const loadImg = new Image();
							loadImg.src = image;
							loadImg.onload = () => resolve(image);
							loadImg.onerror = (err) => reject(err);
						});
					};

					Promise.all(images.map((image) => loadImage(image))).then(
						() => setImgsLoaded(true)
					);
				}
			});
		});

		observer.observe(imageRef.current);

		return () => {
			observer.unobserve(observerRefValue);
		};
	}, [images, tabItems.items]);

	const generateImPolicyParam = (url) => {
		if (url?.includes('impolicy')) {
			return url.substring(url.indexOf('&'), 0) + imPolicyParam;
		}

		return `${url}?impolicy=downsize${imPolicyParam}`;
	};

	const calculateAspectRatio = (ratio) => {
		switch (ratio) {
			case 'classic (4:3)':
				return '4/3';

			case 'cinemascope (21:9)':
				return '21/9';

			default:
				return '16/9';
		}
	};

	const calculateHeight = (img, mobile, heightMobile) => {
		let height = '';
		if (img === undefined && mobile) {
			height = `${parseInt(heightMobile, 10) * 0.5}px`;
		} else if (img === undefined && !mobile) {
			height = `${parseInt(heightMobile, 10)}px`;
		} else if (img !== undefined) {
			height = '100%';
		}

		return height;
	};

	const isClassic = () => aspectRatio === 'classic';

	const contentContainerStyles = {
		[verticalPos]: 0,
		[horizontalPos]: 0,
		textAlign: horizontalPos
	};

	const shouldRenderDisclaimer = !isMobile && activeItem?.disclaimer !== '';

	const renderImages = () => {
		// Load first image and then hide it once we have the images loaded
		if (!imgsLoaded) {
			return (
				<img
					ref={imageRef}
					data-src={generateImPolicyParam(firstImageTab)}
					alt="itemImageAlt"
				/>
			);
		} else {
			// It's going to map all images but only show the active one / currentItem
			return images.map((img, i) => (
				<img
					src={generateImPolicyParam(img)}
					key={img}
					hidden={tabItems.currentItem !== i}
					alt={labels.get(tabItems.items[i].imageAlt)}
					style={{
						width: isClassic() ? '80%' : '100%',
						visibility: img === undefined ? 'hidden' : 'visible',
						height: calculateHeight(img, isMobile, mobileHeight),
						aspectRatio: calculateAspectRatio(aspectRatio),
						marginLeft: isClassic() ? 'auto' : '0',
						marginRight: isClassic() ? 'auto' : '0'
					}}
				/>
			));
		}
	};

	return (
		<div
			className="tab-pane-img-wrapper"
			title={labels.get(activeItem?.imageAlt)}
			aria-label={labels.get(activeItem?.imageAlt)}
			role="tabpanel"
		>
			{renderImages()}

			<div
				className={`${activeItem.overlay && 'tab-pane-img-overlay'}`}
			/>
			{shouldRenderDisclaimer && (
				<Disclaimer textColor={contentTextColor} />
			)}
			{!isMobile && (
				<div
					className={`tab-pane-content-container text-${contentTextColor}`}
					style={contentContainerStyles}
				>
					{activeItem?.heading && (
						<h2>
							{getHtmlWithDLData(labels.get(activeItem.heading))}
						</h2>
					)}
					{activeItem?.content &&
						getHtmlWithDLData(labels.get(activeItem.content))}
				</div>
			)}
		</div>
	);
};

export default TabPane;
