export const specialCharactersMap = {
	'+': '%2B'
};

/**
 * @param {string} value
 * @returns {string}
 * @example
 * encodeQueryValue('a+b'); // 'a%2Bb'
 */
export const encodeQueryValue = (value) => {
	const specialCharsString = Object.keys(specialCharactersMap).join(',');
	const regex = new RegExp(`[${specialCharsString}]`, 'g');
	return value.replace(regex, (match) => specialCharactersMap[match]);
};
